import { useMount } from 'ahooks'

import { hostEnv } from 'dbaas/services/endpoint'

export function useDevFavicon() {
  useMount(() => {
    const link = document.querySelector('link[rel="shortcut icon"]')
    const href = link?.getAttribute('href')
    const map: Record<typeof hostEnv, string> = {
      localhost: 'favicon_dev',
      dev: 'favicon_dev',
      staging: 'favicon_staging',
      prod: 'favicon'
    }
    if (href && hostEnv !== 'prod') {
      link?.setAttribute('href', href?.replace('favicon', map[hostEnv]))
    }
  })
}
