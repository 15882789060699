import * as Sentry from '@sentry/react'
import { Auth0DecodedHash, Auth0ParseHashError } from 'auth0-js'

import config from 'config'
import { CentralLoginMetricsReq, loginMetrics } from 'dbaas/services'

const loginReceivedAt = `${config.AUTH0_NS}/login_received_at`

export function loginTracing(
  params:
    | {
        status: 'ok'
        result: Auth0DecodedHash
      }
    | { status: 'error'; result: Auth0ParseHashError | null }
) {
  let startTime = new Date().getTime()
  if (
    params.status === 'ok' &&
    params.result.idTokenPayload &&
    typeof params.result.idTokenPayload[loginReceivedAt] === 'number'
  ) {
    startTime = params.result.idTokenPayload[loginReceivedAt]
  }
  const ts = startTime / 1000
  const transaction = Sentry.startTransaction({
    name: 'login',
    status: params.status,
    startTimestamp: ts
  })

  const span = transaction.startChild({
    op: 'login-callback',
    status: params.status,
    description: 'login callback from auth0'
  })
  span.startTimestamp = ts
  span.finish()
  transaction.finish()

  const req: CentralLoginMetricsReq = {
    event_type: 'LOGIN',
    event_time: (startTime / 1000).toFixed(),
    cost: ((new Date().getTime() - startTime) / 1000).toString(),
    event_result: 'SUCCESS'
  }
  if (params.status === 'ok') {
    req.token = params.result.idToken
  } else {
    req.event_result = 'FAILD'
    req.error = params.result?.error
    req.desc = params.result?.errorDescription
  }

  setTimeout(() => {
    loginMetrics(req, {
      _ignoreStatus: [401],
      isHandleErr: true
    }).catch((e) => {})
  }, 5 * 1000)
}
