const LOG_ERROR_SCENE = {
  CoreJourney: 'CoreJourney'
} as const

export const tracingRoutes = [
  { path: '/console/clusters', exact: true, scene: LOG_ERROR_SCENE.CoreJourney },
  { path: '/console/clusters/:id/overview', exact: true, scene: LOG_ERROR_SCENE.CoreJourney },
  { path: '/console/clusters/:id/chat2query', exact: true, scene: LOG_ERROR_SCENE.CoreJourney },
  { path: '/console/clusters/:id/modifying', exact: true },
  { path: '/console/clusters/:id/branches', exact: true },
  { path: '/console/clusters/:id/imports', exact: true },
  { path: '/console/clusters/:id/imports/create-import', exact: true },
  { path: '/console/clusters/:id/data-migration', exact: true },
  { path: '/console/clusters/:id/data-migration/create', exact: true },
  { path: '/console/clusters/:id/data-migration/:jobId', exact: true },
  { path: '/console/clusters/:id/changefeed', exact: true },
  { path: '/console/clusters/:id/changefeed/create-changefeed', exact: true },
  { path: '/console/clusters/:id/changefeed/edit-changefeed', exact: true },
  { path: '/console/clusters/:id/changefeed/:changefeedId', exact: true },
  { path: '/console/clusters/:id/changefeed/replication', exact: true },
  { path: '/console/clusters/:id/changefeed/replication/create', exact: true },
  { path: '/console/clusters/:id/backup', exact: true },
  { path: '/console/clusters/:id/backup/restore', exact: true },
  { path: '/console/clusters/:id/diagnosis', exact: true },
  { path: '/console/clusters/:id/diagnosis/slow-query', exact: true },
  { path: '/console/clusters/:id/diagnosis/slow-query/detail', exact: true },
  { path: '/console/clusters/:id/diagnosis/sql-statement', exact: true },
  { path: '/console/clusters/:id/diagnosis/sql-statement/detail', exact: true },
  { path: '/console/clusters/:id/diagnosis/perf-insight', exact: true },
  { path: '/console/clusters/:id/diagnosis/perf-insight/:adviceId', exact: true },
  { path: '/console/clusters/:id/diagnosis/key-viz', exact: true },
  { path: '/console/clusters/:id/alerts', exact: true },
  { path: '/console/clusters/:id/alerts/rules', exact: true },
  { path: '/console/clusters/:id/events', exact: true },
  { path: '/console/clusters/:id/metrics', exact: true },
  { path: '/console/clusters/:id/calibrate_resource', exact: true },
  { path: '/console/clusters/:id/sql-users', exact: true },
  { path: '/enterprise/signin/:company', exact: true }
]

export const sceneRoutes = [...tracingRoutes.filter((route) => !!route.scene)]
