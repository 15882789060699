import { Box, ColorScheme, DefaultProps, useMantineColorScheme } from '@tidbcloud/uikit'

import { ReactComponent as LogoWithoutText } from 'media/logo/tidb-cloud-no-text.svg'
import { ReactComponent as LogoWithText } from 'media/logo/tidb-cloud-with-text.svg'

interface LogoProps extends DefaultProps, Omit<React.SVGAttributes<SVGSVGElement>, 'display' | 'opacity'> {
  colorScheme?: ColorScheme
  withText?: boolean
}

export const Logo = ({ colorScheme, withText, ...restProps }: LogoProps) => {
  const { colorScheme: ctxColorScheme } = useMantineColorScheme()
  const scheme = colorScheme || ctxColorScheme
  const isDark = scheme === 'dark'
  const fillColor = isDark ? 'white' : void 0
  const logo = withText ? LogoWithText : LogoWithoutText

  return <Box component={logo} height={24} {...restProps} fill={fillColor} title="TiDB Cloud" />
}
