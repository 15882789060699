import { startTransaction, Transaction } from '@sentry/react'
import type { RouteConfig } from '@sentry/react/types/reactrouter'
import type { Span, SpanContext } from '@sentry/types'
import { browserPerformanceTimeOrigin } from '@sentry/utils'
import { matchPath } from 'react-router-dom'

import { getVisibilityWatcher } from './getVisibilityWatcher'
import { resourceTimingEntryToSpanData } from './request'

export type Match = { path: string; url: string; params: Record<string, any>; isExact: boolean }
export type MatchPath = (pathname: string, props: string | string[] | any, parent?: Match | null) => Match | null

/**
 * Matches a set of routes to a pathname
 * Based on implementation from
 */
export function matchRoutes(
  routes: RouteConfig[],
  pathname: string,
  matchPath: MatchPath,
  branch: Array<{ route: RouteConfig; match: Match }> = []
): Array<{ route: RouteConfig; match: Match }> {
  routes.some((route) => {
    const match = route.path
      ? matchPath(pathname, route)
      : branch.length
      ? branch[branch.length - 1].match // use parent match
      : computeRootMatch(pathname) // use default "root" match

    if (match) {
      branch.push({ route, match })

      if (route.routes) {
        matchRoutes(route.routes, pathname, matchPath, branch)
      }
    }

    return !!match
  })

  return branch
}

export const getNavigationTiming = () => {
  if (!window.performance) {
    return null
  }
  return window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
}

/**
 * Converts from milliseconds to seconds
 * @param time time in ms
 */
function msToSec(time: number): number {
  return time / 1000
}

function computeRootMatch(pathname: string): Match {
  return { path: '/', url: '/', params: {}, isExact: pathname === '/' }
}

export function normalizeTransactionName(
  pathname: string,
  allRoutes: RouteConfig[] = []
): [string, RouteConfig | undefined] {
  if (allRoutes.length === 0) {
    return [pathname, undefined]
  }

  const branches = matchRoutes(allRoutes, pathname, matchPath)
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let x = 0; x < branches.length; x++) {
    if (branches[x].match.isExact) {
      return [branches[x].match.path, branches[x].route]
    }
  }

  return [pathname, undefined]
}

function startChild(transaction: Transaction, { startTimestamp, ...ctx }: SpanContext): Span {
  if (startTimestamp && transaction.startTimestamp > startTimestamp) {
    transaction.startTimestamp = startTimestamp
  }

  return transaction.startChild({
    startTimestamp,
    ...ctx
  })
}

function addNavigationSpans(transaction: Transaction, entry: Record<string, any>, timeOrigin: number): void {
  ;['unloadEvent', 'redirect', 'domContentLoadedEvent', 'loadEvent', 'connect'].forEach((event) => {
    _addPerformanceNavigationTiming(transaction, entry, event, timeOrigin)
  })
  _addPerformanceNavigationTiming(transaction, entry, 'secureConnection', timeOrigin, 'TLS/SSL', 'connectEnd')
  _addPerformanceNavigationTiming(transaction, entry, 'fetch', timeOrigin, 'cache', 'domainLookupStart')
  _addPerformanceNavigationTiming(transaction, entry, 'domainLookup', timeOrigin, 'DNS')
  _addRequest(transaction, entry, timeOrigin)
}

/** Create performance navigation related spans */
function _addPerformanceNavigationTiming(
  transaction: Transaction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: Record<string, any>,
  event: string,
  timeOrigin: number,
  description?: string,
  eventEnd?: string
): void {
  const end = eventEnd ? (entry[eventEnd] as number | undefined) : (entry[`${event}End`] as number | undefined)
  const start = entry[`${event}Start`] as number | undefined
  if (!start || !end) {
    return
  }
  startChild(transaction, {
    op: 'browser',
    description: description || event,
    startTimestamp: timeOrigin + msToSec(start),
    endTimestamp: timeOrigin + msToSec(end)
  })
}

/** Create measure related spans */
export function _addMeasureSpans(
  transaction: Transaction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: Record<string, any>,
  startTime: number,
  duration: number,
  timeOrigin: number
): number {
  const measureStartTimestamp = timeOrigin + startTime
  const measureEndTimestamp = measureStartTimestamp + duration

  startChild(transaction, {
    description: entry.name as string,
    endTimestamp: measureEndTimestamp,
    op: entry.entryType as string,
    startTimestamp: measureStartTimestamp
  })

  return measureStartTimestamp
}

/** Create request and response related spans */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _addRequest(transaction: Transaction, entry: Record<string, any>, timeOrigin: number): void {
  startChild(transaction, {
    op: 'browser',
    description: 'request',
    startTimestamp: timeOrigin + msToSec(entry.requestStart as number),
    endTimestamp: timeOrigin + msToSec(entry.responseEnd as number)
  })

  startChild(transaction, {
    op: 'browser',
    description: 'response',
    startTimestamp: timeOrigin + msToSec(entry.responseStart as number),
    endTimestamp: timeOrigin + msToSec(entry.responseEnd as number)
  })
}

export interface ResourceEntry extends PerformanceResourceTiming {
  renderBlockingStatus?: string
}

const isCached = (entry: ResourceEntry) => {
  // from local cache or 304
  // see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/transferSize
  // and
  return (
    (entry.transferSize === 0 && entry.decodedBodySize > 0) || (entry.transferSize !== 0 && entry.encodedBodySize === 0)
  )
}

let _cachedResources = 0
let _resources = 0

/** Create resource-related spans */
export function addResourceSpans(
  transaction: Transaction,
  entry: ResourceEntry,
  resourceName: string,
  startTime: number,
  duration: number,
  timeOrigin: number
): void {
  const startTimestamp = timeOrigin + startTime
  const endTimestamp = startTimestamp + duration
  if (entry.initiatorType === 'xmlhttprequest' || entry.initiatorType === 'fetch') {
    const spanData = resourceTimingEntryToSpanData(entry)
    const span = startChild(transaction, {
      description: resourceName,
      op: 'http.c_request',
      endTimestamp,
      startTimestamp
    })
    spanData.forEach((data) => span.setData(...data))
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {}
  if ('transferSize' in entry) {
    data['http.response_transfer_size'] = entry.transferSize
  }
  if ('encodedBodySize' in entry) {
    data['http.response_content_length'] = entry.encodedBodySize
  }
  if ('decodedBodySize' in entry) {
    data['http.decoded_response_content_length'] = entry.decodedBodySize
  }
  if ('renderBlockingStatus' in entry) {
    data['resource.render_blocking_status'] = entry.renderBlockingStatus
  }

  const cached = isCached(entry)

  if (cached) {
    _cachedResources += 1
  }
  _resources += 1

  startChild(transaction, {
    description: resourceName,
    endTimestamp,
    op: entry.initiatorType ? `resource.${entry.initiatorType}` : 'resource.other',
    startTimestamp,
    data,
    tags: {
      c_is_cached: cached
    }
  })
}

let _performanceCursor: number = 0

export const addConnectionMeasurement = (transaction: Transaction) => {
  const timing = getNavigationTiming()
  if (!timing) {
    return
  }
  const fip = timing.responseEnd - timing.responseStart
  // first index page
  transaction.setMeasurement('c_fip', fip, 'millisecond')
}

export const addRequestSpan = (transaction: Transaction, entry: ResourceEntry) => {}

interface MeasurePerformaceOptions {
  name: string
  operation: 'pageload' | 'navigation'
  includeOrigins?: string[]
  beforeFinish?: (t: Transaction) => void
}

export const measurePerformace = (options: MeasurePerformaceOptions) => {
  if (!window.performance) {
    return
  }
  const performance = window.performance
  const performanceEntries = performance.getEntries()
  const timeOrigin = msToSec(browserPerformanceTimeOrigin || 0)
  const { operation, includeOrigins = [], name, beforeFinish } = options
  const transaction = startTransaction({
    name,
    op: operation,
    startTimestamp: timeOrigin
  })
  transaction.setTag('c_type', operation)

  performanceEntries.slice(_performanceCursor).forEach((entry: ResourceEntry) => {
    const startTime = msToSec(entry.startTime)
    const duration = msToSec(entry.duration)

    switch (entry.entryType) {
      case 'navigation': {
        addNavigationSpans(transaction, entry, timeOrigin)
        break
      }
      case 'mark':
      case 'paint':
      case 'measure': {
        _addMeasureSpans(transaction, entry, startTime, duration, timeOrigin)
        const firstHidden = getVisibilityWatcher()
        // Only report if the page wasn't hidden prior to the web vital.
        const shouldRecord = entry.startTime < firstHidden.firstHiddenTime

        if (entry.name === 'first-paint' && shouldRecord) {
          transaction.setMeasurement('fp', entry.startTime, 'millisecond')
        }
        if (entry.name === 'first-contentful-paint' && shouldRecord) {
          transaction.setMeasurement('fcp', entry.startTime, 'millisecond')
        }
        break
      }
      case 'resource': {
        if (includeOrigins.length && !includeOrigins.some((origin) => entry.name.includes(origin))) {
          return
        }
        const resourceName = entry.name.replace(window.location.origin, '')
        addResourceSpans(transaction, entry, resourceName, startTime, duration, timeOrigin)
        break
      }
      default:
      // Ignore other entry types.
    }
  })
  beforeFinish?.(transaction)
  if (_resources) {
    transaction.setMeasurement('c_cache_hit', _cachedResources / _resources, 'percent')
  }

  transaction.finish(new Date().getTime() / 1000)

  _performanceCursor = Math.max(performanceEntries.length - 1, 0)
  _cachedResources = 0
  _resources = 0
}
