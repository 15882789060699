import React, { useEffect } from 'react'

import { tracingRoutes } from 'dbaas/routes/tracing'
import { dbaasUrl } from 'dbaas/services/endpoint'
import history from 'dbaas/services/history'
import { measurePerformace, normalizeTransactionName } from 'dbaas/utils/tracing/performance'

class HeroTracing {
  loaded = false
  prevName = ''
  apiError = false
  feError = false
  constructor() {}
  start() {
    // in case of navigate
    history.listen((location) => {
      this.loaded = true
      this.resetError()
    })
  }
  resetError() {
    this.apiError = false
    this.feError = false
  }
  finish(name: string = '') {
    this.loaded = true
    this.prevName = name
  }
}

export const heroTracing = new HeroTracing()

export const HeroComponent: React.FC<{ name?: string }> = ({ name }) => {
  useEffect(() => {
    if (heroTracing.loaded) {
      return
    }
    const [prevName, route] = normalizeTransactionName(window.location.pathname, tracingRoutes)
    measurePerformace({
      operation: 'pageload',
      includeOrigins: [window.location.origin, dbaasUrl, 'observability.tidbcloud.com'],
      name: name || 'c_' + prevName,
      beforeFinish: (t) => {
        if (heroTracing.loaded) {
          t.setTag('from', heroTracing.prevName)
          t.setTag('to', name || prevName)
        }
        if (route?.scene) {
          t.setTag('scene', route.scene)
        }
      }
    })
    heroTracing.finish(name || prevName)
  }, [])

  return <></>
}
