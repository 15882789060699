/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * gRPC for our DBaaS central service
 * notably uses gRPC-Gateway with OpenAPI
 * OpenAPI spec version: 0.1
 */

export type CentralClusterDevTierClusterType = typeof CentralClusterDevTierClusterType[keyof typeof CentralClusterDevTierClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CentralClusterDevTierClusterType = {
  DEVTIER_UNSPECIFIED: 'DEVTIER_UNSPECIFIED',
  DEVTIER_CLASSIC: 'DEVTIER_CLASSIC',
  DEVTIER_SERVERLESS: 'DEVTIER_SERVERLESS',
} as const;
