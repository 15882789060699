import { getVariant } from '@tidb-cloud-uikit/theme'
import { useMantineTheme } from '@tidbcloud/uikit'

import { UIKitThemeProvider } from './UIKitThemeProvider'

const ThemeAdjustor: React.FC = ({ children }) => {
  const theme = useMantineTheme()

  // Replace `variant` here
  theme.fn.variant = getVariant(theme)

  return <>{children}</>
}

export const UIWrapper: React.FC = ({ children }) => {
  return (
    <UIKitThemeProvider>
      <ThemeAdjustor>{children}</ThemeAdjustor>
    </UIKitThemeProvider>
  )
}
