import { LogTransport, LogTransportInfo, LOG_LEVEL } from '../logger'

import { isSentryCaptureContext } from './sentry'
import { LogArgs } from './types'

export class ConsoleTransport implements LogTransport {
  private handlers: Record<LOG_LEVEL, Function>

  constructor() {
    this.handlers = {
      [LOG_LEVEL.ERROR]: console.error,
      [LOG_LEVEL.WARNING]: console.warn,
      [LOG_LEVEL.INFO]: console.info,
      [LOG_LEVEL.DEBUG]: console.debug
    }
  }

  log(info: LogTransportInfo, ...args: LogArgs[]) {
    this.handlers[info.level]?.(...args.filter((arg) => !isSentryCaptureContext(arg)))
  }
  handleException(e: any) {
    console.warn(e)
  }
}
