import * as Sentry from '@sentry/react'
import { merge } from 'lodash-es'
import { matchPath } from 'react-router-dom'

import { sceneRoutes } from 'dbaas/routes/tracing'
import { matchRoutes } from 'dbaas/utils/tracing/performance'

import { Logger, LOG_LEVEL } from './logger'
import { ConsoleTransport } from './transports/console'
import { SentryTransport } from './transports/sentry'
import { LogArgs } from './transports/types'

export enum LogErrorType {
  NetworkError = 'NetworkError',
  APIError = 'APIError',
  AuthError = 'AuthError',
  StaticResourceError = 'StaticResourceError',
  CustomError = 'CustomError',
  UnknownError = 'UnknownError',
  TrackingError = 'TrackingError'
}

class TriageLogger extends Logger {
  protected log(level: LOG_LEVEL, ...args: LogArgs[]) {
    if (this.level < level) {
      return
    }
    if (!this.context) {
      this.context = {
        tags: {
          errorTriage: LogErrorType.CustomError
        }
      }
    }
    this.transports?.forEach((tsp) => {
      const context = this.beforeLog?.(level) || {}
      tsp.log({ level, context: merge({ ...this.context }, context) }, ...args)
    })
    this.context = undefined
  }

  setTriage(type: LogErrorType) {
    this.context = {
      tags: {
        errorTriage: type
      }
    }
  }

  get network() {
    this.setTriage(LogErrorType.NetworkError)
    return this
  }
  get api() {
    this.setTriage(LogErrorType.APIError)
    return this
  }
  get auth() {
    this.setTriage(LogErrorType.AuthError)
    return this
  }
  get tracking() {
    this.setTriage(LogErrorType.TrackingError)
    return this
  }
  get unknown() {
    this.setTriage(LogErrorType.UnknownError)
    return this
  }
}

export const log = new TriageLogger({
  level: LOG_LEVEL.DEBUG,
  transports: [new ConsoleTransport(), new SentryTransport(Sentry)],
  beforeLog(level) {
    const branches = matchRoutes(sceneRoutes, window.location.pathname, matchPath)

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let x = 0; x < branches.length; x++) {
      if (branches[x].match.isExact) {
        const scene = branches[x].route.scene
        if (!scene) {
          return {}
        }
        return {
          tags: {
            scene
          }
        }
      }
    }
    return {}
  }
})
