import { detectMobile } from './dom'

export const shouldSkipError = (msg: string) => {
  const isMobile = detectMobile()

  if (
    [
      /ResizeObserver loop limit exceeded/,
      /window.lintrk is not a function/,
      /Load failed/,
      /NetworkError when attempting to fetch resource/
    ].some((r) => r.test(msg)) ||
    (msg.match(/Failed to fetch/) && !msg.match(/Auth Callback Error/)) ||
    (isMobile &&
      [
        /Calling init() more than once is not allowed/,
        /getRestrictions is not a function/,
        /Cannot read property 'title' of null/,
        /Cannot read property 'L' of undefined/,
        /Object.fromEntries is not a function/,
        /Cannot read properties of undefined \(reading 'domInteractive'\)/
      ].some((r) => r.test(msg)))
  ) {
    return true
  }

  return false
}

export const shouldDowngrade = (msg: string) => {
  if (
    // cluster
    (/The cluster could not be found/.test(msg) &&
      [/API Error: getConnectionService/, /API Error: getCluster/].some((t) => t.test(msg))) ||
    // impersonated user
    (/impersonated user is a read only user/.test(msg) &&
      [/API Error: getAwsCmek/, /API Error: createUserVisitHistory/].some((t) => t.test(msg)))
  ) {
    return true
  }
  return false
}
