import { Box, BackgroundImage, useMantineColorScheme } from '@tidbcloud/uikit'

import bg from 'media/signup-sso-bg.svg'
import darkBg from 'media/signup-sso-dark-bg.svg'

export const BackgroundMask: React.FC<{ enableDark?: boolean }> = ({ children, enableDark = false }) => {
  const { colorScheme } = useMantineColorScheme()

  const isDark = colorScheme === 'dark' && enableDark

  return (
    <Box sx={() => ({ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 })}>
      <BackgroundImage
        w={'100%'}
        h={'100%'}
        src={isDark ? darkBg : bg}
        sx={() => ({ opacity: isDark ? 0.7 : 0.6, mixBlendMode: 'luminosity' })}
      >
        {children}
      </BackgroundImage>
    </Box>
  )
}
