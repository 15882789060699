import type { CaptureContext, ScopeContext } from '@sentry/types'
import { merge } from 'lodash-es'

import { LogArgs } from './transports/types'

export enum LOG_LEVEL {
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  DEBUG = 3
}

export interface LogTransportInfo {
  level: LOG_LEVEL
  context?: CaptureContext
}

export interface LogTransport {
  log: (info: LogTransportInfo, ...args: LogArgs[]) => void
  handleException?: (exception: any, context?: CaptureContext) => void
}

export interface LoggerOptions {
  name?: string
  level: LOG_LEVEL
  transports: LogTransport[]
  beforeLog?: (level: LOG_LEVEL) => Partial<ScopeContext>
}

export class Logger {
  protected name: LoggerOptions['name']
  protected level: LoggerOptions['level']
  protected transports: LoggerOptions['transports']
  protected context?: CaptureContext
  protected beforeLog?: LoggerOptions['beforeLog']

  constructor(opts: LoggerOptions = { level: LOG_LEVEL.INFO, transports: [] }) {
    this.name = opts.name || ''
    if (typeof opts.level === 'number') {
      this.level = opts.level
    } else {
      this.level = LOG_LEVEL.INFO
    }
    this.transports = opts.transports || []
    this.beforeLog = opts.beforeLog
  }

  protected log(level: LOG_LEVEL, ...args: LogArgs[]) {
    if (this.level < level) {
      return
    }
    this.transports?.forEach((tsp) => {
      tsp.log({ level, context: this.context })
    })
  }

  error(...args: LogArgs[]) {
    this.log(LOG_LEVEL.ERROR, ...args)
  }

  warning(...args: LogArgs[]) {
    this.log(LOG_LEVEL.WARNING, ...args)
  }

  info(...args: LogArgs[]) {
    this.log(LOG_LEVEL.INFO, ...args)
  }

  debug(...args: LogArgs[]) {
    this.log(LOG_LEVEL.DEBUG, ...args)
  }

  exception(e: any, context?: Partial<ScopeContext>) {
    this.transports.forEach((tsp) => {
      tsp.handleException?.(e, merge(this.context, context))
    })
  }

  addTransport(transport: LogTransport) {
    this.transports.push(transport)
  }
}
