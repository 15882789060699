export const config = {
  NODE_ENV: process.env.NODE_ENV,
  SERVICE_URL: process.env.REACT_APP_SERVICE_URL || '',
  // auth0
  AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID || '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  AUTH0_NS: 'https://tidbcloud.com',
  ALLOWED_OKTA_CONNECTIONS: (process.env.REACT_APP_ALLOWED_OKTA_CONNECTIONS || '').split(','),

  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || '',
  MIXPANEL_HOST: process.env.REACT_APP_MIXPANEL_HOST || '',

  // Check https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
  DEPLOY_ID: process.env.DEPLOY_ID || '',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_DEBUG: !!process.env.REACT_APP_SENTRY_DEBUG,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  RELEASE_ENV: process.env.REACT_APP_RELEASE_ENV || 'dev',
  VERSION: process.env.VERSION || '',
  SKIP_CAPTCHA: !!process.env.REACT_APP_SKIP_CAPTCHA,
  BRANCH: process.env.BRANCH || '',
  API_SAMPLING_RATE: 0.05,
  ENABLE_DATASERVICE_USAGE: !!process.env.REACT_APP_ENABLE_DATASERVICE_USAGE,
  ENABLE_GLOBAL_NOTICE: !!process.env.REACT_APP_ENABLE_GLOBAL_NOTICE,
  ENABLE_SERVERLESS_ESTIMATION: !!process.env.REACT_APP_ENABLE_SERVERLESS_ESTIMATION,

  // audit logging
  AUDIT_LOG_TIMEOUT: 30 * 1000,

  SIGNUP_TIMEOUT: 30 * 1000,

  CREATE_TICKET_URL:
    'https://pingcap-ticket-sandbox-313.atlassian.net/servicedesk/customer/portal/6/group/11/create/30',

  SUPPORT_PORTAL:
    'https://pingcap-ticket-sandbox-313.atlassian.net/servicedesk/customer/user/requests?page=1&statuses=open',

  featureFlags: {
    enableSSOSignupInvitation: JSON.parse(process.env.REACT_APP_ENABLE_SSO_SIGNUP_INVITATION || 'true'),
    enableContract: JSON.parse(process.env.REACT_APP_ENABLE_CONTRACT || 'true'),
    enableImportAwsAK: JSON.parse(process.env.REACT_APP_ENABLE_IMPORT_AWS_AK || 'false'),
    enableImportGcsAK: JSON.parse(process.env.REACT_APP_ENABLE_IMPORT_GCS_AK || 'false'),
    enableDmScale: JSON.parse(process.env.REACT_APP_ENABLE_DM_SCALE || 'false'),
    enableWeeklySchedule: JSON.parse(process.env.REACT_APP_ENABLE_WEEKLY_SCHEDULE || 'false')
  },

  PINGCHAT_URL: '',

  GITHUB_INSTALL_URL: '',

  GITHUB_CLIENT_ID: process.env.REACT_APP_SERVERLESS_BRANCH_CLIENT_ID || '',

  // o11y
  O11Y_TEST_HOST_DOMAIN_SUFFIX: process.env.REACT_APP_O11Y_TEST_HOST_DOMAIN_SUFFIX
}

if (config.RELEASE_ENV === 'prod') {
  config.CREATE_TICKET_URL = 'https://tidb.support.pingcap.com/servicedesk/customer/portal/6/group/11/create/30'
  config.SUPPORT_PORTAL = 'https://tidb.support.pingcap.com/servicedesk/customer/user/requests?page=1&statuses=open'
  config.PINGCHAT_URL = 'https://clinic.pingcap.com'
}

switch (config.RELEASE_ENV) {
  case 'dev':
    config.GITHUB_INSTALL_URL = 'https://github.com/apps/tidb-cloud-branching-dev/installations/new'
    break
  case 'staging':
    config.GITHUB_INSTALL_URL = 'https://github.com/apps/tidb-cloud-branching-staging/installations/new'
    break
  case 'prod':
    config.GITHUB_INSTALL_URL = 'https://github.com/apps/tidb-cloud-branching/installations/new'
    break
}

window.APP_ENV = config.RELEASE_ENV

export default config
