import { camelCase, snakeCase } from 'lodash-es'

import { getClusterType } from 'dbaas/features/ClusterManagement/cluster'
import { store } from 'dbaas/stores'

import { eventTracking } from '.'

// dataset keys that start with `mp`,
// like `data-mp-event`, not including `data-mp-propagation-ignored`
// `mp` stands for `mixpanel`
function getDatasetByNamingConvention(o: DOMStringMap) {
  return Object.entries(o)
    .filter((i) => i[0].startsWith('mp') && snakeCase(i[0]).split('_')?.[0] === 'mp')
    .reduce((acc, next) => {
      const name = camelCase(next[0].replace('mp', ''))

      if (next[1]) {
        acc[name] = next[1]
      }

      return acc
    }, {} as Record<string, string>)
}

export function enableAutoClickTracking(
  targetNode: Node & ParentNode = document,
  observerInit: MutationObserverInit = {
    subtree: true,
    childList: true,
    attributes: true,
    attributeFilter: ['data-mp-event', 'href']
  }
) {
  const tracked: WeakSet<HTMLElement> = new WeakSet()

  function selectNodes(el: ParentNode) {
    return el.querySelectorAll('button[data-mp-event], a')
  }

  function trackClick(this: HTMLButtonElement | HTMLAnchorElement, e: Event) {
    /**
     * Prevent click tracking during propagation
     *
     * If the element with `data-mp-event`
     * has an attribute `data-mp-propagation-ignored`,
     * its click event will not be tracked during propagation.
     *
     * e.g.
     * <button data-mp-event="btn clicked">
     *   <a data-mp-event="link clicked" data-mp-propagation-ignored></a>
     * </button>
     * When users click the "a" element,
     * the click event with mp-event "link clicked" will be tracked,
     * but the mp-event "btn clicked" will not be tracked
     */
    const targetEL = e.target
    const isPropagated =
      targetEL !== this &&
      targetEL instanceof Element &&
      Array.from(selectNodes(this)).some(
        (el) => el.hasAttribute('data-mp-propagation-ignored') && el.contains(targetEL)
      )

    if (isPropagated) {
      return
    }

    const payload: Record<string, string> = {
      from: window.location.href
    }
    if (store.currentCluster) {
      payload.clusterType = getClusterType(store.currentCluster)
    }

    let eventName = ''
    const props = getDatasetByNamingConvention(this.dataset)
    if (props.event) {
      eventName = props.event
      Object.assign(payload, props)
    } else if (this instanceof HTMLAnchorElement) {
      payload.to = this.href
      if (this.host === window.location.host) {
        eventName = 'Click Route Link'
      } else {
        eventName = 'Click Outbound Link'
      }
    }

    if (eventName) {
      eventTracking(eventName, payload)
    }
  }

  function addNode(node: Node | ParentNode) {
    if (node instanceof HTMLAnchorElement || node instanceof HTMLButtonElement) {
      node.addEventListener('click', trackClick)
      tracked.add(node)
    } else if ('querySelectorAll' in node) {
      selectNodes(node).forEach(addNode)
    }
  }

  function removeNode(node: Node | ParentNode) {
    if (node instanceof HTMLAnchorElement || node instanceof HTMLButtonElement) {
      node.removeEventListener('click', trackClick)
      tracked.delete(node)
    } else if ('querySelectorAll' in node) {
      selectNodes(node).forEach(addNode)
    }
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        removeNode(mutation.target)
        addNode(mutation.target)
      } else if (mutation.type === 'childList') {
        mutation.addedNodes.forEach(addNode)
        mutation.removedNodes.forEach(removeNode)
      }
    })
  })

  selectNodes(targetNode).forEach(addNode)

  observer.observe(targetNode, observerInit)

  return function cleanup() {
    observer.disconnect()
  }
}
