interface Ram {
  reportLogin?: boolean
}
const memory: Ram = {
  reportLogin: false
}

export const ram = {
  set<K extends keyof Ram>(key: K, value: Ram[K]) {
    memory[key] = value
  },
  get<K extends keyof Ram>(key: K): Ram[K] {
    return memory[key]
  },
  clear<K extends keyof Ram>(key: K) {
    memory[key] = undefined
  }
}
