import { browserPerformanceTimeOrigin } from '@sentry/utils'

export function extractNetworkProtocol(nextHopProtocol: string): { name: string; version: string } {
  let name = 'unknown'
  let version = 'unknown'
  let _name = ''
  for (const char of nextHopProtocol) {
    // http/1.1 etc.
    if (char === '/') {
      ;[name, version] = nextHopProtocol.split('/')
      break
    }
    // h2, h3 etc.
    if (!isNaN(Number(char))) {
      name = _name === 'h' ? 'http' : _name
      version = nextHopProtocol.split(_name)[1]
      break
    }
    _name += char
  }
  if (_name === nextHopProtocol) {
    // webrtc, ftp, etc.
    name = _name
  }
  return { name, version }
}

function getAbsoluteTime(time: number): number {
  return ((browserPerformanceTimeOrigin || performance.timeOrigin) + time) / 1000
}

export function resourceTimingEntryToSpanData(resourceTiming: PerformanceResourceTiming): [string, string | number][] {
  const { name, version } = extractNetworkProtocol(resourceTiming.nextHopProtocol)

  const timingSpanData: [string, string | number][] = []

  timingSpanData.push(['network.protocol.version', version], ['network.protocol.name', name])

  if (!browserPerformanceTimeOrigin) {
    return timingSpanData
  }
  return [
    ...timingSpanData,
    ['http.request.redirect_start', getAbsoluteTime(resourceTiming.redirectStart)],
    ['http.request.fetch_start', getAbsoluteTime(resourceTiming.fetchStart)],
    ['http.request.domain_lookup_start', getAbsoluteTime(resourceTiming.domainLookupStart)],
    ['http.request.domain_lookup_end', getAbsoluteTime(resourceTiming.domainLookupEnd)],
    ['http.request.connect_start', getAbsoluteTime(resourceTiming.connectStart)],
    ['http.request.secure_connection_start', getAbsoluteTime(resourceTiming.secureConnectionStart)],
    ['http.request.connection_end', getAbsoluteTime(resourceTiming.connectEnd)],
    ['http.request.request_start', getAbsoluteTime(resourceTiming.requestStart)],
    ['http.request.response_start', getAbsoluteTime(resourceTiming.responseStart)],
    ['http.request.response_end', getAbsoluteTime(resourceTiming.responseEnd)]
  ]
}
