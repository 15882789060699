import { captureMessage, captureException, SeverityLevel } from '@sentry/react'
import type { CaptureContext, ScopeContext } from '@sentry/types'
import { merge } from 'lodash-es'

import { LogTransport, LogTransportInfo, LOG_LEVEL } from '../logger'

import { LogArgs } from './types'

const Severity = {
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
  Debug: 'debug'
} as const

export interface SentryInstance {
  captureMessage: typeof captureMessage
  captureException: typeof captureException
}

export function isSentryCaptureContext(ctx: LogArgs): ctx is Partial<ScopeContext> {
  if (ctx !== null && typeof ctx === 'object') {
    if (
      'user' in ctx ||
      'level' in ctx ||
      'extra' in ctx ||
      'tags' in ctx ||
      'fingerprint' in ctx ||
      'requestSession' in ctx ||
      'propagationContext' in ctx
    )
      return true
  }
  return false
}

export class SentryTransport implements LogTransport {
  instance: SentryInstance
  levelMap: Record<LOG_LEVEL, SeverityLevel>
  level: LOG_LEVEL

  constructor(ins: SentryInstance, opts: { level: LOG_LEVEL } = { level: LOG_LEVEL.WARNING }) {
    this.instance = ins
    this.level = opts.level
    this.levelMap = {
      [LOG_LEVEL.ERROR]: Severity.Error,
      [LOG_LEVEL.WARNING]: Severity.Warning,
      [LOG_LEVEL.INFO]: Severity.Info,
      [LOG_LEVEL.DEBUG]: Severity.Debug
    }
  }

  log(info: LogTransportInfo, ...args: LogArgs[]) {
    if (this.level < info.level) {
      return
    }
    if (Array.isArray(args)) {
      const message = args.reduce(
        (prev, next) => {
          if (isSentryCaptureContext(next)) {
            prev.context = merge(prev.context, next)
          } else {
            prev.contents.push(next)
          }
          return prev
        },
        { contents: [], context: {} } as {
          contents: (Error | string | undefined)[]
          context: Partial<ScopeContext>
        }
      )
      this.instance.captureMessage(
        message.contents.join('\n'),
        merge(
          {
            level: this.levelMap[info.level],
            ...(info.context || {})
          },
          message.context
        )
      )
    }
  }

  handleException(e: any, context?: CaptureContext) {
    this.instance.captureException(e, context)
  }
}
