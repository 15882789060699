export enum OrgPlanStatus {
  NEW_USER = 'NEW_USER',
  FREE_TRIAL = 'FREE_TRIAL',
  POC = 'POC',
  ON_DEMAND = 'ON_DEMAND'
}

export interface PlanDetail {
  value: OrgPlanStatus
  name: string
  description: string
  features: string[]
  button: {
    text: string
    link: string
    tooltip?: string
  }
  guide: string
}

/**
 * @deprecated
 * not referenced in our codebase
 */
export default [
  {
    value: OrgPlanStatus.POC,
    name: 'Proof of Concept',
    description: 'Test out your mission critical applications with our dedicated technical support.',
    features: [
      '14 day free trial period*',
      'Unlimited cluster size',
      'Unlimited number of databases',
      'Unlimited number of users',
      'Unlimited backups',
      'Dedicated technical support'
    ],
    button: {
      text: 'Apply for a PoC Trial',
      link: '/console/poc-application'
    },
    guide: 'Meet our technical staff'
  },
  // {
  //   value: OrgPlanStatus.FREE_TRIAL,
  //   name: 'Developer Tier',
  //   description:
  //     'For learning and exploring TiDB Cloud with basic configuration options.',
  //   features: [
  //     'No credit card required to start',
  //     '1 year free trial',
  //     '1 GiB of OLTP storage and 1 GiB of OLAP storage'
  //   ],
  //   button: {
  //     text: 'Get Started for Free',
  //     link: '/console/clusters/create-cluster?tier=dev',
  //     tooltip: 'You have reached the maximum number of Developer Tier clusters.'
  //   },
  //   guide: 'Get Started for Free'
  // },
  {
    value: OrgPlanStatus.FREE_TRIAL,
    name: 'Serverless Tier',
    description: 'For learning and exploring TiDB Cloud with minimal configuration required.',
    features: ['No credit card required to start', 'Access to full HTAP functionality'],
    button: {
      text: 'Get Started for Free',
      link: '/console/clusters/create-cluster?tier=dev',
      tooltip: 'You have reached the maximum number of Serverless clusters.'
    },
    guide: 'Get Started for Free'
  },
  {
    value: OrgPlanStatus.ON_DEMAND,
    name: 'Dedicated Tier',
    description: 'For production application with mission-critical workload and advanced configuration controls.',
    features: [
      'Dedicated network, compute and storage resource',
      'Access to the full functionalities of TiDB Cloud',
      'Available on more cloud service providers and regions'
    ],
    button: {
      text: 'Get Full Access Today',
      link: '/console/clusters/create-cluster?tier=dedicated'
    },
    guide: 'Discounted'
  }
]
