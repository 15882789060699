import { Colors, DarkColors, Theme, darkThemeColors, themeColors } from '@tidb-cloud-uikit/theme'
import { type MantineProviderProps, MantineProvider, ColorSchemeProvider, ColorScheme } from '@tidbcloud/uikit'
import { useColorScheme, useHotkeys, useLocalStorage } from '@tidbcloud/uikit/hooks'
import React, { useEffect, useState } from 'react'

export const UIKitThemeProvider: React.FC<MantineProviderProps> = ({ theme = Theme, children }) => {
  const preferredColorScheme = useColorScheme()
  const [colorScheme] = useLocalStorage<ColorScheme | 'auto'>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true
  })
  const [colorSchemeResult, setColorSchemeResult] = useState<ColorScheme>('light')

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorSchemeResult(value || (colorSchemeResult === 'dark' ? 'light' : 'dark'))

  useHotkeys([['mod+J', () => toggleColorScheme()]])

  useEffect(() => {
    if (colorScheme === 'auto') {
      toggleColorScheme(preferredColorScheme)
    } else {
      toggleColorScheme(colorScheme)
    }
  }, [colorScheme, preferredColorScheme])

  return (
    <ColorSchemeProvider colorScheme={colorSchemeResult} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        theme={{
          ...theme,
          colorScheme: colorSchemeResult,
          colors: colorSchemeResult === 'dark' ? darkThemeColors : themeColors,
          // colors: {
          //   ...themeColors,
          //   gray: colorScheme === 'dark' ? darkThemeColors.gray : themeColors.gray,
          // },
          other: colorSchemeResult === 'dark' ? DarkColors : Colors
        }}
      >
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
