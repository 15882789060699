import { startTransaction } from '@sentry/react'
import { reactRouterV5Instrumentation } from '@sentry/react'
import type { RouterHistory, RouteConfig } from '@sentry/react/types/reactrouter'
import type { ReactRouterInstrumentation } from '@sentry/react/types/types'
import { TransactionContext } from '@sentry/types'
import { browserPerformanceTimeOrigin } from '@sentry/utils'

import { log } from 'common/log'

import { addConnectionMeasurement } from './performance'
import { MatchPath } from './performance'

/**
 * get first-meaningful-paint
 */
function getFMP(observeTime = 5000) {
  if (
    !Promise ||
    !window.performance ||
    !window.performance.timing ||
    !window.requestAnimationFrame ||
    !window.MutationObserver
  ) {
    log.warning('FMP can not be retrieved')
    Promise.reject(new Error('fmp can not be retrieved'))
  }

  const getStartTime = () => {
    const timing = window.performance.timing
    // const startTime = timing.navigationStart || timing.fetchStart
    const startTime = browserPerformanceTimeOrigin || timing.navigationStart || timing.fetchStart
    return startTime
  }

  const promise: Promise<{ duration: number; startTime: number }> = new Promise((resolve) => {
    const observedPoints: { score: number; t: number }[] = []
    const observer = new window.MutationObserver(() => {
      const innerHeight = window.innerHeight
      function getDomMark(dom: Element, level: number) {
        const length = dom.children ? dom.children.length : 0
        let sum = 0
        const tagName = dom.tagName
        if (tagName !== 'SCRIPT' && tagName !== 'STYLE' && tagName !== 'META' && tagName !== 'HEAD') {
          if (dom.getBoundingClientRect && dom.getBoundingClientRect().top < innerHeight) {
            sum += level * length
          }
          if (length > 0) {
            const children = dom.children
            for (let i = 0; i < length; i++) {
              sum += getDomMark(children[i], level + 1)
            }
          }
        }
        return sum
      }
      window.requestAnimationFrame(() => {
        const t = new Date().getTime() - getStartTime()
        // @ts-ignore
        const score = getDomMark(document, 1)
        observedPoints.push({
          score,
          t
        })
      })
    })
    observer.observe(document, {
      childList: true,
      subtree: true
    })

    setTimeout(() => {
      observer.disconnect()
      const rates = []
      for (let i = 1; i < observedPoints.length; i++) {
        if (observedPoints[i].t !== observedPoints[i - 1].t) {
          rates.push({
            t: observedPoints[i].t,
            rate: observedPoints[i].score - observedPoints[i - 1].score
          })
        }
      }
      rates.sort((a, b) => b.rate - a.rate)
      const startTime = getStartTime()
      if (rates.length > 0) {
        resolve({ duration: rates[0].t, startTime })
      } else {
        resolve({ duration: observeTime, startTime })
      }
    }, observeTime)
  })
  return promise
}

export function addFMPMark(
  history: RouterHistory,
  routes: RouteConfig[],
  matchPath?: MatchPath
): ReactRouterInstrumentation {
  return (startRoutingTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true) => {
    const customStartTransaction = (context: TransactionContext) => {
      const t = startRoutingTransaction(context)
      if (t && t.op === 'pageload') {
        addConnectionMeasurement(t)
      }
      return t
    }
    return reactRouterV5Instrumentation(history, routes, matchPath)(
      customStartTransaction,
      startTransactionOnPageLoad,
      startTransactionOnLocationChange
    )
  }
}
