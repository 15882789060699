import { GLOBAL_OBJ } from '@sentry/utils'

export const WINDOW = GLOBAL_OBJ as typeof GLOBAL_OBJ & Window

export interface OnHiddenCallback {
  (event: Event): void
}

export const onHidden = (cb: OnHiddenCallback, once?: boolean): void => {
  const onHiddenOrPageHide = (event: Event): void => {
    if (event.type === 'pagehide' || WINDOW.document.visibilityState === 'hidden') {
      cb(event)
      if (once) {
        window.removeEventListener('visibilitychange', onHiddenOrPageHide, true)
        window.removeEventListener('pagehide', onHiddenOrPageHide, true)
      }
    }
  }
  window.addEventListener('visibilitychange', onHiddenOrPageHide, true)
  // Some browsers have buggy implementations of visibilitychange,
  // so we use pagehide in addition, just to be safe.
  window.addEventListener('pagehide', onHiddenOrPageHide, true)
}

let firstHiddenTime = -1

const initHiddenTime = (): number => {
  // If the document is hidden and not prerendering, assume it was always
  // hidden and the page was loaded in the background.
  // @ts-ignore
  return WINDOW.document.visibilityState === 'hidden' && !WINDOW.document.prerendering ? 0 : Infinity
}

const trackChanges = (): void => {
  // Update the time if/when the document becomes hidden.
  onHidden(({ timeStamp }) => {
    firstHiddenTime = timeStamp
  }, true)
}

export const getVisibilityWatcher = (): {
  readonly firstHiddenTime: number
} => {
  if (firstHiddenTime < 0) {
    // If the document is hidden when this code runs, assume it was hidden
    // since navigation start. This isn't a perfect heuristic, but it's the
    // best we can do until an API is available to support querying past
    // visibilityState.
    firstHiddenTime = initHiddenTime()
    trackChanges()
  }
  return {
    get firstHiddenTime() {
      return firstHiddenTime
    }
  }
}
